export const onClientEntry = async () => {
  window.onload = () => { document.body.classList.add('dom-loaded'); }

  // Interaction observer polyfill.
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname === '/') {
    document.body.classList.add('page-home');
  }
  else {
    document.body.classList.remove('page-home');
  }
}
