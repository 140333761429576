import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'gatsby';

import Social from './social.js';

const MobileMenu = () => {
  return (
    <Popover className="mobile-menu lg:hidden">
      <Popover.Button className="text-white">
        <span className="sr-only">Open menu</span>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 bg-transparent" aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="mobile-menu-panel shadow-md bg-gray-900 p-8 pt-6 pr-12 pb-12">
          <div className="close-button-wrapper pt-2 pr-2 sm:pt-4 sm:pr-4">
            <Popover.Button
              type="button"
              className="bg-transparent sm:bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-offset-0 sm:focus:ring-offset-0 focus:ring-gray-500 sm:focus:ring-gray-500"
            >
              <span className="sr-only">Close</span>
              <svg className="h-12 w-12 sm:w-10 sm:h-10 bg-transparent" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Popover.Button>
          </div>
          <nav>
            <ul className="text-white">
              <li className="p-2"><Popover.Button as={Link} activeClassName="active" className="text-lg tracking-wide no-underline transition opacity-75" to="/">Home</Popover.Button></li>
              <li className="p-2"><Popover.Button as={Link} activeClassName="active" className="text-lg tracking-wide no-underline transition opacity-75" to="/music/">Music</Popover.Button></li>
              <li className="p-2"><Popover.Button as={Link} activeClassName="active" className="text-lg tracking-wide no-underline transition opacity-75" to="/biography/">Biography</Popover.Button></li>
              <li className="p-2"><Popover.Button as={Link} activeClassName="active" className="text-lg tracking-wide no-underline transition opacity-75" to="/contact/">Contact</Popover.Button></li>
            </ul>
          </nav>
          <div className="text-white pt-8">
            <Social />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default MobileMenu;
