import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Marquee = () => (
  <div className="marquee">
    <div>
      <div>
        <section className="feature feature-top">
          <h2 className="text-lg">New Album</h2>
          <h3 className="font-display">
            Face to the Sun
          </h3>
          <a href="https://bfan.link/face-to-the-sun" aria-label="Listen to the album Face to the Sun" rel="nofollow noreferrer">
            <StaticImage
              src="../images/Face to The Sun-Kithara Cover 3000.jpg"
              width={400}
              alt=""
              placeholder="blurred"
            />
          </a>
          <a aria-hidden="true" href="https://bfan.link/face-to-the-sun" className="button bg-transparent" tabIndex="-1" rel="nofollow noreferrer">Listen</a>
        </section>
      </div>
    </div>
  </div>
);

export default Marquee;
