import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import { LogoSemibold } from './logo';
import MobileMenu from './mobileMenu.js';
import Social from './social.js';

const Header = () => {

  const { ref, inView } = useInView({});

  useEffect(() => {
    const el = typeof document !== "undefined" && document.querySelector('.marquee-wrapper');
    ref(el)
  }, [ref])

  return (
    <header className={`header text-white transition transition-colors duration-300 ease-in-out ${inView ? 'bg-transparent-gray' : 'bg-gray-900'}`}>
      <div className="header-inner cluster">
        <Link to="/" className="logo" aria-label="Home">
          <LogoSemibold fill="white" />
        </Link>
        <nav className="hidden lg:block ml-auto">
          <ul className="main-menu cluster mt-0">
            <li><Link activeClassName="active" className="opacity-75 no-underline text-base tracking-wide transition hover:opacity-100" to="/music/">Music</Link></li>
            <li><Link activeClassName="active" className="opacity-75 no-underline text-base tracking-wide transition hover:opacity-100" to="/biography/">Biography</Link></li>
            <li><Link activeClassName="active" className="opacity-75 no-underline text-base tracking-wide transition hover:opacity-100" to="/contact/">Contact</Link></li>
          </ul>
        </nav>
        <div className="hidden lg:block">
          <Social iconSize={6} />
        </div>
      </div>
      <MobileMenu />
    </header>
  );
};

export default Header;
