import React from 'react';
// import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import sal from 'sal.js';

// import Modal from '../components/modal';
import Header from '../components/header';
import Footer from '../components/footer';
import Marquee from '../components/marquee';

// import 'sal.js/dist/sal.css';

import '../assets/fluid-type.css';
import '../assets/fluid-space.css';
import '../assets/main.css';
import '../assets/compositions.css';
import '../assets/layout.css';
import '../assets/animation.css';

const Layout = ({ children, location }) => {

  // const [openModal, setOpenModal] = useState(false);

  // useEffect(() => {
  //   let timeId = setTimeout(() => {
  //     setOpenModal(true)
  //   }, 3000);
  //   return () => {
  //     clearTimeout(timeId)
  //   };
  // }, []);

  if (location && location.pathname === '/') {
    return (
      <>
        <div className="marquee-wrapper bg-gray-900">
          <Header />
          <Marquee />
        </div>
       {children}
        {/*
        <Modal
          open={openModal}
          handleSetOpen={setOpenModal}
        />
        */}
        <Footer />
      </>
    );
  };

  return (
    <>
      <Header />
      {children}
      {/*
      <Modal
        open={openModal}
        handleSetOpen={setOpenModal}
      />
      */}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
