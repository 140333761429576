import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-gray-900">
      <div>
        <p><small>Clara Ponty &copy; 2022-2024</small></p>
      </div>
    </footer>
  );
};

export default Footer;
